import React from "react";
import styled from "styled-components";
import { Box, Card as MuiCard, Grid } from "@material-ui/core";
import theme from "../theme";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const StyledCard = styled(MuiCard)`
  ${({ $img }) => `background-image: url(${$img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Card = ({ backgroundImage, heading, subHeading, info }) => {
    const [showMore, setShowMore] = React.useState(false);

    const showMoreHandler = () => {
        setShowMore(!showMore);
    };

    return (
        <Grid item>
            <StyledCard $img={backgroundImage} onClick={showMoreHandler}>
                <Box
                    minWidth={{ xs: theme.spacing(37.5), lg: theme.spacing(47.75) }}
                    height={{ xs: theme.spacing(59.25), lg: theme.spacing(77) }}
                    position="relative"
                    display="flex"
                    overflow="hidden"
                >
                    <Grid container direction="column" justify="space-between">
                        <Box
                            component="div"
                            width="100%"
                            pt={{ xs: 2.5, lg: 3 }}
                            pl={{ xs: 4, lg: 5 }}
                        >
                            <Box
                                fontWeight="bold"
                                fontSize={{ xs: theme.spacing(2.75), lg: theme.spacing(3.75) }}
                                color="secondary.main"
                            >
                                {heading}
                            </Box>
                            <Box
                                fontSize={{ xs: theme.spacing(1.5), lg: theme.spacing(2) }}
                                color="secondary.main"
                                style={{ textTransform: "uppercase" }}
                            >
                                {subHeading}
                            </Box>
                        </Box>
                        <Box
                            width="100%"
                            height={theme.spacing(14.5)}
                            bgcolor="white"
                            component="div"
                            display="flex"
                            onClick={showMoreHandler}
                        >
                            <Grid container direction="column" justify="center">
                                <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    style={{ color: "#999999" }}
                                >
                                    <Box
                                        pl={{ xs: 4, lg: 5 }}
                                        color="inherit"
                                        fontSize={theme.spacing(2.25)}
                                    >
                                        Viac info
                  </Box>
                                    <Box pr={{ xs: 4, lg: 5 }}>
                                        <KeyboardArrowDownIcon color="inherit" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Box position="absolute" width="100%" bottom="0">
                        <Collapse in={showMore}>
                            <Box
                                height={{ xs: theme.spacing(59.25), lg: theme.spacing(77) }}
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                bgcolor="rgb(0 0 0 / 0.4)"
                            >
                                <Box
                                    px={{ xs: 4 }}
                                    py={{ xs: 3.5 }}
                                    display="flex"
                                    justifyContent="flex-end"
                                    onClick={showMoreHandler}
                                >
                                    <CloseIcon color="secondary" />
                                </Box>
                                <Box
                                    px={5.5}
                                    py={4}
                                    bgcolor="primary.main"
                                    height={{ xs: theme.spacing(55) }}
                                >
                                    <Grid container direction="column" spacing={3}>
                                        {info.map((i) => (
                                            <Grid item key={i}>
                                                <Box
                                                    fontSize={{
                                                        xs: theme.spacing(1.5),
                                                        lg: theme.spacing(2.25),
                                                    }}
                                                    color="secondary.main"
                                                    fontWeight="bold"
                                                >
                                                    {i}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                </Box>
            </StyledCard>
        </Grid>
    );
};

export default Card;
