import React from "react";
import styled from "styled-components";
import { Box, Card, Grid } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import theme from "../theme";
import { Link } from 'gatsby'

const StyledBox = styled(Box)`
  ${({ $img }) => `background-image: url("${$img.childImageSharp.fluid.src}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ArticleCard = ({ slug, heading, date, text, img }) => (
    <Grid item>
        <Card>
            <Box
                // width={{ xs: theme.spacing(37.75), lg: theme.spacing(47.75) }}
                height={{ xs: theme.spacing(70.5), lg: theme.spacing(92.5) }}
                display="flex"
            >
                <Grid container direction="column">
                    <StyledBox
                        width="100%"
                        $img={img}
                        height={{ xs: theme.spacing(26.5), lg: theme.spacing(35) }}
                    />

                    <Box display="flex" px={{ xs: 3 }} py={{ xs: 2 }}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Box
                                    fontSize={{ xs: theme.spacing(2.5), lg: theme.spacing(3.75) }}
                                    color="primary.main"
                                    fontWeight="bold"
                                    height={{ xs: theme.spacing(10.5), lg: theme.spacing(14.75) }}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    width="100%"
                                >
                                    {heading}
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    fontSize={{ xs: theme.spacing(1.5), lg: theme.spacing(2) }}
                                    color="#A0A0A0"
                                >
                                    {''}
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    height={{ xs: theme.spacing(17.5), lg: theme.spacing(24.75) }}
                                    fontSize={{ xs: theme.spacing(1.5), lg: theme.spacing(2) }}
                                    color="#2E2E2E"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    width="100%"
                                >
                                    {text}
                                </Box>
                            </Grid>
                            <Box px={{ xs: 2 }} py={{ xs: 1 }}>
                                <Link to={slug.replace(/\/$/, '')} style={{ marginBottom: "1px", textDecoration: "none" }}>
                                    <Grid

                                        container
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            fontSize={{ xs: theme.spacing(1.75) }}
                                            fontWeight="bold"
                                            color="primary.main"
                                        >
                                            Čítať viac
                  </Box>
                                        <KeyboardArrowRightIcon color="primary" />
                                    </Grid>
                                </Link>

                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Card>
    </Grid>
);

export default ArticleCard;
