import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import camera from "../img/Camera.png";
import grafika from "../img/grafika.png";
import theme from "../theme";

const StyledCamera = styled.img`
  width: 100%;
`;

const StyledGrafika = styled.img`
  position: absolute;

  top: 15%;

  width: 80%;
  left: 30%;
`;

const Hero = () => (
  <Grid container justify="center">
    <Grid item xs={12} md={10} container direction="row-reverse">
      <Grid item xs={7} sm={4}>
        <Box mt={{ xs: -4, lg: -6 }} position="relative">
          <StyledCamera src={camera} />
          <Hidden xsDown>
            <StyledGrafika src={grafika} />
          </Hidden>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} container alignItems="center">
        <Grid>
          <Box
            fontSize={{
              xs: theme.spacing(2.5),
              sm: theme.spacing(3),
              md: theme.spacing(4),
              lg: theme.spacing(5),
            }}
            width="90%"
            mb={3}
            pt={2}
            color="text.primary"
          >
            Špecializujeme sa na poradenstvo, montáž, údržbu a prevádzkovanie
            kamerových systémov.
          </Box>
          <Box
            color="primary.main"
            fontWeight="bold"
            fontSize={{
              xs: theme.spacing(2),
              sm: theme.spacing(2.25),
              md: theme.spacing(2.5),
              lg: theme.spacing(4),
            }}
          >
            Sme spoločnosť Kamerové systémy, s.r.o.
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Hero;
