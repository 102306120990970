/* eslint-disable react/display-name */
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from 'prop-types'
import ArticleCard from "./ArticleCard";
import { graphql, StaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from "swiper/react";
import theme from "../theme";
import SwiperCore, { Pagination } from "swiper";
import { Box, Hidden } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
SwiperCore.use([Pagination]);

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: ${theme.palette.primary.main};
  }
`;


const Articles = ({ data }) => {
    const [swiper, setSwiper] = useState(null);
    const { edges: posts } = data.allMarkdownRemark
    const memoizedPostData = React.useMemo(() => posts.sort((a, b) => {
        const partsA = a.node.frontmatter.date.split("/");
        const partsB = b.node.frontmatter.date.split("/");
        return new Date(`20${partsB[2]}`, partsB[1], partsB[0]) - new Date(`20${partsA[2]}`, partsA[1], partsA[0])
    }), [posts])

    return (
        <Box height={{ xs: theme.spacing(76.5), lg: theme.spacing(100) }} display="flex" position="relative">
            <StyledSwiper
                pagination={{
                    clickable: true,
                    bulletClass: "swiper-pagination-bullet kkt",
                }}
                onSwiper={setSwiper}
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                    768: {

                        slidesPerView: 2,
                    },
                    1024: {

                        slidesPerView: 3,
                    },
                    1200: {

                        slidesPerView: 4,
                    },
                }}
            >
                {memoizedPostData.map(({ node: post }) => (
                    <SwiperSlide key={post.id}>
                        <ArticleCard slug={post.fields.slug} heading={post.frontmatter.title} date={post.frontmatter.date} text={post.frontmatter.description} img={post.frontmatter.featuredimage} />
                    </SwiperSlide>
                ))}
            </StyledSwiper>

            <Hidden smDown>
                <Box style={{ transform: "rotate(180deg)", cursor: "pointer" }} onClick={() => swiper.slidePrev()} position="absolute" top={0} bottom={0} left={0} display="flex" alignItems="center" justifyContent="space-between" zIndex={9}>
                    <KeyboardArrowRightIcon />
                </Box>
                <Box style={{ cursor: "pointer" }} onClick={() => swiper.slideNext()} position="absolute" top={0} bottom={0} right={0} display="flex" alignItems="center" justifyContent="space-between" zIndex={9}>
                    <KeyboardArrowRightIcon />
                </Box>
            </Hidden>

        </Box>

    );
};

Articles.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}


export default () => (
    <StaticQuery
        query={graphql`
            query ArticlesQuery {
                allMarkdownRemark(
                    sort: { order: ASC, fields: [frontmatter___date] }
                    filter: { frontmatter: { templateKey: { eq: "clanky-post" } } }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                description
                                date
                                featuredimage {
                                childImageSharp {
                                    fluid(maxWidth: 360, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                                }
                            }
                }
            }
            }
        }
      `}
        render={(data, count) => <Articles data={data} count={count} />}
    />
)

