import React from "react";
import Grid from "@material-ui/core/Grid";
import img1 from "../img/PIC_1.png";
import img2 from "../img/PIC_2.png";
import img3 from "../img/PIC_3.png";
import img4 from "../img/PIC_4.png";
import Card from "./Card";
import styled from "styled-components";

const data = [
  {
    backgroundImage: img1,
    heading: "Poradenstvo",
    subHeading: "projekčné | tecnické | gdpr",
    info: [
      "Spolupracujeme pri návrhu a tvorbe projektovej dokumentácie",
      "Poskytujeme nezávislé odborné poradenstvo a súdnoznalecké posudky.",
      "Posudzujeme cenové ponuky a pomáhame tvoriť jednotnú rozhodovaciu bázu pri výberových konaniach",
      "Dobre sa orientujeme v problematike ochrany osobných údajov (GDPR) v súvislosti s kamerovými systémami",
    ],
  },
  {
    backgroundImage: img2,
    heading: "Montáž",
    subHeading: "kamerový systém na mieru",
    info: [
      "Na základe obhliadky objektu bezplatne spracujeme kvalifikovanú cenovú ponuku",
      "Najčastejšie obhliadané objekty: rodinné domy bytové domy firemné objekty",
      "Po schválení cenovej ponuky sa uzatvára zmluva s pevnou cenou",
      "Nasleduje odborná montáž s vysokým dôrazom na kvalitu aj estetiku prevedenia",
    ],
  },
  {
    backgroundImage: img3,
    heading: "Údržba",
    subHeading: "servis | revízie | audity",
    info: [
      "Poskytujeme záručný aj nezáručný servis kamerových systémov všetkých značiek",
      "Vykonávame revízie a audity kamerových systémov a technických riešení",
      "Spracúvame odborné posudky a technické správy",
    ],
  },
  {
    backgroundImage: img4,
    heading: "Prevádzkovanie",
    subHeading: "PREVZATIE ZODPOVEDNOSTI | BYTOVÉ DOMY",
    info: [
      "Špeciálne pre bytové domy poskytujeme produkt úplneho prevádzkovania nainštalovaného kamerového systému",
      "Preberáme legislatívnu (GDPR) zodpovednosť za monitorovanie priestorov",
      "Vyhľadávame incidenty, extrahujeme záznamy pre Policajný zbor a súdy",
    ],
  },
];

const StyledWrapper = styled(Grid)`
  overflow: scroll;
`;

const Cards = () => {
  return (
    <StyledWrapper container spacing={2} wrap="nowrap" justify="space-between">
      {data.map((d) => (
        <Card key={d.heading} {...d} />
      ))}
    </StyledWrapper>
  );
};

export default Cards;
