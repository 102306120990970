import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React from "react";
import Articles from "../components/Articles";
import Cards from "../components/Cards";
import Contact from "../components/Contact";
import Faqs from "../components/Faqs";
import Hero from "../components/Hero";
import Reference from "../components/Reference";
import { Element } from "react-scroll";
import Layout from "../components/Layout"
import CookieBanner from "../components/CookieBanner";
import Logos from "../components/Logos";

const IndexPage = () => (
    <Layout>
        <Container maxWidth="xl" style={{ overflow: "hidden" }}>
            <Hero />
            <Box mt={{ xs: 5, md: 10 }}>
                <Cards />
            </Box>
            <Box my={{ xs: 5 }}>
                <Element name="ref">
                    <Reference />
                </Element>
            </Box>
            <Box my={{ xs: 5 }}>
                <Element name="log">
                    <Logos />
                </Element>
            </Box>
            <Box mb={{ xs: 5 }}>
                <Element name="artc">
                    <Articles />
                </Element>
            </Box>
            <Box mb={{ xs: 5 }}>
                <Element name="faqs">
                    <Faqs />
                </Element>
            </Box>
            <Box mb={{ xs: 5 }}>
                <Element name="contact">
                    <Contact />
                </Element>
            </Box>

        </Container>
        <CookieBanner/>
    </Layout>
);
export default IndexPage;
