import { Card } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import theme from "../theme";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination } from "swiper";
import styled from "styled-components";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

SwiperCore.use([Pagination]);

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: ${theme.palette.primary.main};
  }
`;

const data = [
  {
    title: "Nie je to tak dávno čo sme hľadali odpovede na otázky bezpečnosti našej spoločnosti.",
    desc: "Nakoniec sme dostali oveľa viac ako sú len technické riešenia s kvalitnou IT podporou. Firma “Kamerové systémy s.r.o.”  u nás realizovala niekoľko projektov s inštaláciou monitorovacích, záznamových a signalizačných prvkov, ktoré plne pokrývajú naše predstavy ohľadne ochrany osôb a majetku a sú v právnom súlade s platnou legislatívou. Vrelo odporúčame ako partnera a poradcu na oblasť bezpečnosti.",
    p1: "DUNATEX, a.s.",
    p2: "",
  },
  {
    title: "Firma Kamerové systémy, s.r.o. realizovala v našom bytovom dome",
    desc: "na ul. Jána Smreka v Bratislave inštaláciu bezpečnostného kamerového systému. Prístup a komunikácia dodávateľa vo všetkých fázach projektu (príprava, realizácia, zaškolenie užívateľa, záručný a pozáručný servis,) bol pro-zákaznícky a na profesionálnej úrovni.",
    p1: "zástupca vlastníkov",
    p2: "",
  },
  {
    title: "Firmu Kamerové systémy s.r.o. sme si vybrali z viacerých podobných ponúk.",
    desc: "Komunikácia s nimi bola hneď od začiatku veľmi dobrá a promptná. Samotná realizácia bola bezproblémová a vyhotovená načas. Za necelý rok prevádzky funguje kamerový systém spoľahlivo a plní svoju funkciu. Prístup p. Štubniaka a jeho kolegov je profesionálny a ústretový. Celkovo sme s firmou spokojní a odporúčame ju.",
    p1: "Bytový dom",
    p2: "Bratislava - Staré Mesto",
  },
  {
    title: "Priateľ mi odporučil kamerové systémy, špecialisti na bezpečnosť",
    desc: "Zavolal som majiteľovi, spravil perfektný rozpočet. Montáž prebehla k našej spokojnosti, kamery bežia bez problémov, servis zabezpečený. Maximálna spokojnosť. Môžem len odporučiť.",
    p1: "Správca hromadných garáží v BA",
    p2: "",
  },
  {
    title: "Spolupracujeme už 7 rokov a som maximálne spokojný.",
    desc: "Moje požiadavky boli v vždy promptne vybavené.",
    p1: "Ron Pinka",
    p2: "OPW EMEA Retails Fueling",
  },
  {
    title: "Spolupraca s firmou Kamerove systemy s.r.o. je na velmi vysokej urovni.",
    desc: "Ocenujem osobny pristup a expresny navrh realizacie kameroveho systemu v bytovom dome. Vsetko funguje podla dohody a v pripade potreby je pan Stubniak ochotny promptne riesit situaciu a zodpovedat otazky. Co povazujem za velku vyhodu, bolo vypracovanie kompletnej GDPR dokumentacie. Nieje to standardom a usetri to vela usilia. Prajem vela spokojnych klientov.",
    p1: "Jaro Stoličný",
    p2: "bytový dom Bratislava",
  },
  {
    title: "S firmou kamerové systémy mám tie najlepšie skúsenosti.",
    desc: "Skvelá spolupráca ohľadom dodania cenových ponúk a návrhov. Takisto kooperácie pri montáži kamier sú skvele organizované. Školenia poučených osôb sú veľmi zrozumiteľné a školená osoba veľmi rýchlo pochopí a zvládne všetky základné úlohy spojené s obsluhou kamerového systému. Riešenie reklamácii je pre túto firmu absolútna samozrejmosť a pracovníci ich riešia operatívne v prospech zákazníka. Z vlastných skúseností odporúčam aj pre iné bytové domy ",
    p1: "Tomáš Fatul",
    p2: "zaškolený pracovník pre bytové domy Bebravská 1,3,5 + Dvojkrížna 2,4,6",
  },
  {
    title: "Ako zástupca vlastníkov dvoch bytových domov",
    desc: "som s kamerovým systémom nadmieru spokojný či už po stránke funkčnosti alebo stránke podpory a servisu... určite správna voľba.",
    p1: "Michal",
    p2: "",
  },
  {
    title: "Lacné plug-in kamery z hobby marketov majú od skutočných bezpečnostných systémov ďaleko",
    desc: "preto som hľadal profesionálne riešenie s certifikovanou montážou, kvalitným servisom, príslušnou GDPR dokumentáciou a ľudským prístupom. Nemohol som sa rozhodnúť lepšie.",
    p1: "Maroš",
    p2: "rodinný dom Bratislava",
  },
  {
    title: "S dodávkou a inštaláciou kamerového systému sme spokojní a zatiaľ spĺňa naše očakávania.",
    desc: "Prístup riaditeľa aj pracovníkov spoločnosti je seriózny a ústretový od štádia plánovania cez dodávku, montáž až po záručný servis. Firmu Kamerové systémy, s.r.o. môžem na základe vlastných skúseností odporučiť aj iným potenciálnym zákazníkom.",
    p1: "Martin",
    p2: "rodinný dom v Bratislave",
  },

  {
    title: "Bola som veľmi spokojná.",
    desc: "Čistá práca, žiadna špina, bez chýb. Ako keby robili pre svoju domácnosť. Príjazdyboli na čas, dobrá komunikácia. Všetky moje požiadavky boli splnené, som vďačná. Keď budú naši kamaráti potrebovať kamery, určite odporučím.",
    p1: "Ľudmila",
    p2: "rodinný dom pri Bratislave",
  },
  {
    title: "Výborná spolupráca.",
    desc: "Profesionálny prístup, spoľahlivosť. Riešenie podľa požiadaviek. Rýchle reakcie v rámci servisu. Odporúčame!",
    p1: "Ľubomír Slámka",
    p2: "KS Komfort - Servis, s. r. o.",
  },
  {
    title: "Služby spoločnosti Kamerové systémy, s.r.o. využívame niekoľko rokov.",
    desc: "Oceňujeme vysokú profesionalitu, kvalitnú prácu a spoľahlivosť inštalovaného kamerového zariadenia. V bytovom dome máme inštalovaný kamerový systém s9 kamerami.",
    p1: "Ing. Peter Poláček",
    p2: "samospráva domu",
  },
  {
    title: "Na obstaranie kamerového systému bolo vypísané výberové konanie, ktorého sa zúčastnili 3 firmy.",
    desc: "Firmy spracovali ponuky, ktoré boli komisiou vyhodnotené a na realizáciu bola vybraná firma Kamerové systémy s.r.o. Bratislava. Za dobu prevádzky bol systémviac krát využitý na riešenie rôznych problémov narušenia vstupu neoprávnenýchľudí do objektu (vykradnutie bytu).",
    p1: "poverený zástupca vlastníkov",
    p2: "BD Holíčska 3, Bratislava",
  },
  {
    title: "So službami Vašej spoločnosti sme nadmieru spokojní.",
    desc: "Ak sme Vás kedykoľvek požiadali o pomoc, vždy ste nám vedeli promptne vyhovieť, či pri vyberaní kamerového záznamu alebo pri neočakávaných udalostiach, poruchách na systéme. Na základe Vášho prístupu k naším potrebám sa chceme i naďalej s dôverou obracať i v budúcnosti.",
    p1: "JUDr. Milán Morávek",
    p2: "zástupca vlastníkov bytov Rajecká 16-20",
  },
  {
    title: "So spoločnosťou Kamerové systémy dlhodobo spolupracujeme.",
    desc: "Oceňujeme kvalitu a odbornosť, vrátane pozitívneho prístupu.",
    p1: "REESMA, s.r.o.",
    p2: "správcovská spoločnosť",
  },
  {
    title: "Rozhodovali sme sa z viacerých ponúk na zapezpečenie nášho rodinného domu.",
    desc: "Firma Kamerové systémy, s.r.o. ma oslovila najviac hlavne svojim profesionálnymprístupom. Vyjadrujem iba spokojnosť od komunikácie, rýchle vybavenie požiadavky až po montáž a zaškolenie. Určite odporúčam aj ďalším zákazníkom.",
    p1: "Mariana",
    p2: "Rodinný dom v Bratislave",
  },
  {
    title: "Firmu kamerové systémy s.r.o. si vlastníci prvého domu vybrali ešte pred niekoľkými rokmi.",
    desc: "Do tohto času máme fi. Kamerové systémy zabezpečených už niekoľko bytových domov, čo svedčí o spokojnosti vlastníkov bytových domov. Kamerové systémy fungujú spolahlivo. Osadením sa podarilo zabezpečiť väčšiu čistotu v domoch a dodržiavanie domového poriadku. Spoluprácu s firmou hodnotíme ako veľmi dobrú, vysoko profesionálny, ústretový prístup celého tímu Kamerové systémy s.r.o. , konkrétne konateľa spoločnosti p. Štubniaka. Rozhodne odporúčame - smespokojní.",
    p1: "SPRÁVA OBJEKTOV DNV s.r.o.",
    p2: "",
  },
  {
    title: "Oslovil som firmu Kamerové systémy, s.r.o.",
    desc: "a bol som milo prekvapený flexibilitou ako aj cenovou ponukou (mimochodom vypracovaná zadarmo). Anásledne profi prevedenou montážou ako aj zaškolením zo strany pána majiteľa. Jednoznačne odporúčam.",
    p1: "Aure, spol. sr.o.",
    p2: "",
  },
  {
    title: "Spoločnosť SPOKOJNÉ  BÝVANIE, s.r.o. je správcom bytových domov,",
    desc: "ktorý na základe rozhodnutia vlastníkov bytov a NP zabezpečuje prostredníctvom dodávateľov aj ochranu spoločných priestorov v  bytových domov kamerovým systémom. So spoločnosťou Kamerové systémy, s.r.o. spolupracujeme dlhodobo, počas celého obdobia je táto spolupráca bezproblémová, na vysokej profesionálnej úrovni a dodávky a práce sú vykonávané k spokojnosti našich klientov. Preto sa na nich s dôverou obraciame opakovane s novými zákazkami.",
    p1: "SPOKOJNÉ  BÝVANIE, s.r.o.",
    p2: "",
  },
]

const Reference = () => {
  const [swiper, setSwiper] = useState(null);
  return (
    <Card>
      <Box px={{ xs: 3 }} py={{ xs: 2, lg: 6 }} display="flex" alignItems="center" justifyContent="space-between">
        <Box style={{transform: "rotate(180deg)", cursor:"pointer"}} onClick={() => swiper.slidePrev()}>
          <KeyboardArrowRightIcon />
        </Box>
        <StyledSwiper
          pagination={{
            clickable: true,
            bulletClass: "swiper-pagination-bullet kkt",
          }}
          onSwiper={setSwiper}
        >
          {data.map((d) => (
            <SwiperSlide key={d.title}>
              <Grid container>
                <Grid item xs={2} md={3} container justify="flex-end">
                  <Box pr={{ xs: 1, md: 3 }}>
                    <FormatQuoteIcon fontSize="small" />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={7}
                  lg={6}
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item>
                    <Box
                      fontSize={{
                        xs: theme.spacing(2),
                        md: theme.spacing(3.5),
                      }}
                      fontWeight="bold"
                      color="primary.main"
                    >
                      {d.title}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box
                      fontSize={{
                        xs: theme.spacing(1.75),
                        md: theme.spacing(2.5),
                      }}
                      color="#4B4B4B"
                    >
                      <i>
                        {d.desc}
                      </i>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box fontSize={{ xs: theme.spacing(1.75) }} color="black">
                      {d.p1}
                    </Box>
                    {d.p2 && (
                      <Box fontSize={{ xs: theme.spacing(1.5) }} color="#858997">
                        {d.p2}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Box height={theme.spacing(6.5)} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <Box style={{cursor:"pointer"}} onClick={() => swiper.slideNext()}>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
    </Card>
  );
};

export default Reference;
