import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";
import { stubFalse } from "lodash";



const CookieBanner = () => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const cookies = localStorage.getItem("cookies");
    if(!cookies){
      setIsOpen(true)
    } else {
      setIsOpen(stubFalse)
    }
  }, [])

  const handleClick = useCallback(() => {
    localStorage.setItem("cookies", "yes");
    setIsOpen(false);
  }, [])
  return (
    <React.Fragment>

      <Box component="div" display={isOpen ? "flex" : 'none'} position="fixed" bottom={0} left={0} right={0} height={{xs: "200px", sm: "100px"}} bgcolor="white" zIndex={9} alignItems="center">
        <Container maxWidth="xl">
          <Box display="flex" flexDirection={{xs: "column", sm: "row"}} alignItems="center">
            <Box marginBottom={{xs: "20px", sm: "0px"}} marginRight={{xs: "0px", md: "30px"}}>Táto webová stránka používa súbory cookies. Prehliadaním webovej stránky vyjadrujete súhlas s ich používaním.</Box>
            <Button onClick={handleClick} variant="outlined" color="primary">Rozumiem</Button>
          </Box>
        </Container>
      </Box>


    </React.Fragment>
  )
};

export default CookieBanner;
