import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import React from "react";
import theme from "../theme";
import styled from "styled-components";
import A from "../img/logo/01.jpg"
import B from "../img/logo/02.jpg"
import C from "../img/logo/03.jpg"
import D from "../img/logo/04.jpg"
import F from "../img/logo/05.jpg"
import G from "../img/logo/06.jpg"
import H from "../img/logo/07.jpg"
import I from "../img/logo/08.jpg"
import J from "../img/logo/09.jpg"
import K from "../img/logo/10.jpg"
import L from "../img/logo/11.jpg"
import M from "../img/logo/12.jpg"
import N from "../img/logo/13.jpg"
import O from "../img/logo/14.jpg"
import P from "../img/logo/15.jpg"
import R from "../img/logo/16.jpg"
import S from "../img/logo/17.jpg"
import T from "../img/logo/18.jpg"
import U from "../img/logo/19.jpg"
import V from "../img/logo/20.jpg"
import { Link } from "react-scroll";

const StyledLogo = styled.img`
  width:100%;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.palette.primary.main};
  font-weight: bold;
`



const Faqs = () => (
    <Card>
        <Box px={{ xs: 3, lg: 16.5 }} py={{ xs: 2, lg: 12.75 }}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Box
                        fontSize={{ xs: theme.spacing(2.5), lg: theme.spacing(4) }}
                        fontWeight="bold"
                        color="#2E2E2E"
                        textAlign={{ xs: "center", md: "left" }}
                        mb={{xs:"12px", md:"20px"}}
                    >
                        Dlhodobé spolupráce
                    </Box>
                </Grid>

                <Grid item container spacing={2}>
                    
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={A} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={B} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={C} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={D} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={F} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={G} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={H} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={I} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={J} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={K} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={L} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={M} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={N} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={O} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={P} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={R} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={S} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={T} />
                        </Grid>

                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={U} />
                        </Grid>
                        <Grid item xs={3} md={1} lg={1}>
                            <StyledLogo src={V} />
                        </Grid>
                </Grid>
                <Grid item container justify="center">
                    <Box
                        fontSize={{ xs: theme.spacing(2), lg: theme.spacing(2.5) }}
                        mt={{ xs: 2, lg: 5 }}
                        color="#2E2E2E"
                    >
                        <i>
                            Potrebujete konzultovať riešenie? <StyledLink
                                activeClass="active"
                                to="contact"
                                spy
                                smooth
                                offset={-120}
                                duration={500}
                            >kontaktujte nás</StyledLink>.
            </i>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Card>
);

export default Faqs;
